import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

const ExperienceItem = (props) => {
  const {company, position, date, location, logoSrc} = props.data;

  return (
    <li>
      <div className="timeline-badge"><img className="timeline-logo" src={logoSrc} alt="" /></div>
      <div className="timeline-panel">
        <div className="timeline-heading">
          <h3 className="timeline-title"><img className="timeline-logo" src={logoSrc} alt="" />{company}</h3>
          <h4 className="timeline-subtitle">{position}</h4>
        </div>
        <hr className="timeline-divider" />
        <div className="timeline-details">
          <table className="timeline-details text-muted">
            <tbody>
              <tr>
                <td className="timeline-info-icon"><FontAwesomeIcon icon={faClock} /></td>
                <td className="text-left">{date}</td>
              </tr>
              <tr>
                <td className="timeline-info-icon"><FontAwesomeIcon icon={faMapMarkerAlt} /></td>
                <td className="text-left">{location}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="timeline-body">
        </div>
      </div>
    </li>
  );
};

export default ExperienceItem;