import React from "react";

import SEO from "../components/seo";

import SectionMasthead from "../components/SectionMasthead";
import SectionMe from "../components/SectionMe";
import SectionExperience from "../components/SectionExperience";

import "typeface-lato";
import "typeface-catamaran";
import "typeface-muli";

import "bootstrap/dist/css/bootstrap.min.css";

import "../scss/new-age.scss"

import LoadableNavBar from "../components/LoadableNavBar";

const IndexPage = () => {
  // Scroll listener
  return (
    <div>
      <SEO title="Home" />
      <body id="page-top">
        <LoadableNavBar />
        <SectionMasthead />

        <SectionMe />
        <SectionExperience />

        {/* <section className="cta">
          <div className="cta-content">
            <div className="container">
              <h2>Stop waiting.<br />Start building.</h2>
              <a href="#contact" className="btn btn-outline btn-xl js-scroll-trigger">Let's Get Started!</a>
            </div>
          </div>
          <div className="overlay"></div>
        </section>

        <section className="contact bg-primary" id="contact">
        </section> */}

        <footer>
          &copy; Dominic Fung {new Date().getFullYear()}. All Rights Reserved.
        </footer>
      </body>
    </div>
  );
}

export default IndexPage
