import React from "react";

import ExperienceItem from "./ExperienceItem";

import fbIcon from "../images/experience-logos/fb.png";
import optiverIcon from "../images/experience-logos/optiver.png";
import rbaIcon from "../images/experience-logos/rba.png";
import unswIcon from "../images/experience-logos/unsw.png";
import wipeheroIcon from "../images/experience-logos/wipehero.png";

const experienceData = [
  {
    company: 'Facebook Inc.',
    position: 'Software Engineer',
    date: 'March 2020 - Present',
    location: 'Menlo Park, California',
    logoSrc: fbIcon,
  },
  {
    company: 'Facebook Inc.',
    position: 'Software Engineer Intern',
    date: 'June 2019 - August 2019',
    location: 'Menlo Park, California',
    logoSrc: fbIcon,
  },
  {
    company: 'Optiver Asia Pacific',
    position: 'Operations Software Development Intern',
    date: 'March 2019 - May 2019',
    location: 'Sydney, Australia',
    logoSrc: optiverIcon,
  },
  {
    company: 'Optiver Asia Pacific',
    position: 'Application Engineer Intern',
    date: 'November 2018 - February 2019',
    location: 'Sydney, Australia',
    logoSrc: optiverIcon,
  },
  {
    company: 'WipeHero',
    position: 'Software Engineering Intern',
    date: 'November 2017 - January 2018',
    location: 'Sydney, Australia',
    logoSrc: wipeheroIcon,
  },
  {
    company: 'University of New South Wales',
    position: 'Casual Academic - Computer Science Tutor',
    date: 'July 2017 - December 2018',
    location: 'Sydney, Australia',
    logoSrc: unswIcon,
  },
  {
    company: 'Reserve Bank of Australia',
    position: 'Business Administration Trainee',
    date: 'March 2015 - March 2016',
    location: 'Sydney, Australia',
    logoSrc: rbaIcon,
  },
];

const Experience = () => {
  return (
    <section className="experience bg-secondary" id="experience">
      <div className="container">
        <div className="section-heading text-center">
          <h1>Experience</h1>
          <hr />
          <div className="row">
            <div className="col-lg-12 my-auto">
              <ul className="timeline">
                {experienceData.map(data => <ExperienceItem data={data} />)}
              </ul>
              <hr/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
