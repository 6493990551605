import React from "react";

import Highlight from "./Highlight";
import MeItem from "./MeItem";
import SkillItem from "./SkillItem";

import unswIcon from "../images/experience-logos/unsw.png";

import pythonIcon from "../images/dev-icons/python.svg";
import jsIcon from "../images/dev-icons/javascript.svg";
import cIcon from "../images/dev-icons/c.svg";
import javaIcon from "../images/dev-icons/java.svg";
import reactIcon from "../images/dev-icons/react.svg";
import nodeIcon from "../images/dev-icons/nodejs.svg";
import djangoIcon from "../images/dev-icons/django.svg";
import flaskIcon from "../images/dev-icons/flask.svg";
import bashIcon from "../images/dev-icons/bash.svg";
import postgresIcon from "../images/dev-icons/postgres.svg";
import gitIcon from "../images/dev-icons/git.svg";
import dockerIcon from "../images/dev-icons/docker.svg";
import mercurialIcon from "../images/dev-icons/mercurial.png";
import latexIcon from "../images/dev-icons/latex.svg";

const SectionMe = () => {
  return (
    <section className="me bg-primary text-center" id="me">
      <div className="container">
        <div className="row mb-4">
          <div className="col-md-8 mx-auto">
            <h1 className="section-heading">Me in a nutshell</h1>
            <h4>I love automating things!</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-4">
            <MeItem heading="Education">
              <div className="row">
                <div className="col-12 col-sm-3 col-md-12 col-xl-3 education-icon-wrapper">
                  <img className="education-icon" src={unswIcon} alt="unsw" />
                </div>
                <div className="col-12 col-sm-9 col-md-12 col-xl-9 mx-auto">
                  <h4 className="mb-1">B. Science (<Highlight>Computer Science</Highlight>)</h4>
                  <h5>University of New South Wales</h5>
                  <h5>Sydney, Australia</h5>
                </div>
              </div>
            </MeItem>
          </div>
          <div className="col-md-6 mb-4">
            <MeItem heading="Main Programming Languages">
              <div className='mx-auto'>
                <SkillItem name="Python" img={pythonIcon} highlight />
                <SkillItem name="JavaScript" img={jsIcon} highlight />
                <SkillItem name="C" img={cIcon} />
                <SkillItem name="Java" img={javaIcon} />
              </div>
            </MeItem>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-4">
            <MeItem heading="Frameworks">
              <SkillItem name="React" img={reactIcon} highlight />
              <SkillItem name="Node.js" img={nodeIcon} highlight />
              <SkillItem name="Django" img={djangoIcon} highlight />
              <SkillItem name="Flask" img={flaskIcon} highlight />
            </MeItem>
          </div>
          <div className="col-md-6 mb-4">
            <MeItem heading="Tooling and Technology">
              <SkillItem name="Bash" img={bashIcon} />
              <SkillItem name="PostgreSQL" img={postgresIcon} />
              <SkillItem name="Git" img={gitIcon} />
              <SkillItem name="Docker" img={dockerIcon} />
              {/* <SkillItem name="Mercurial" img={mercurialIcon} /> */}
              {/* <SkillItem name="LaTeX" img={latexIcon} /> */}
            </MeItem>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {/* <h5><Highlight>I do have experience with other things - check out the Skills section below!</Highlight></h5> */}
          </div>
        </div>
      </div>
    </section>

  );
};

export default SectionMe;