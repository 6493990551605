import React from "react";

const MeItem = (props) => {
  return (
    <div className="me-panel">
      <h3>{props.heading}</h3>
      <hr className="me-panel-divider" />
      {props.children}
    </div>
  );
};

export default MeItem;