import React from "react";
import Loadable from "react-loadable";

const LoadableNavBar = Loadable({
  loader: () => import("./NavBar"),
  loading: () => null,
});

export default () => {
  return <LoadableNavBar />
};