import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";

import scrollTo from 'gatsby-plugin-smoothscroll';

import Highlight from "./Highlight";
import ScrollIndicator from "./ScrollIndicator";

const Masthead = () => {
  return (
    <header className="masthead">
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-lg-7 my-auto">
            <div className="header-content mx-auto">
              <h1 className="mb-1">G'day!</h1>
              <h2 className="mb-1">I'm <Highlight>Dominic</Highlight>, a <Highlight>Software
                  Engineer</Highlight>.</h2>
              <h5 className="mb-1">Originally from Sydney, Australia.</h5>
              <h5 className="mb-4">Currently in the <Highlight>San Francisco Bay Area</Highlight>.</h5>
              <a href="/dominic_fung_resume.pdf" target="_blank" className="btn btn-outline btn-xl js-scroll-trigger">Check out my resume!</a>
            </div>
          </div>
          <div className="col-lg-5 my-auto">
            <div className="contact">
              <ul className="list-inline list-social">
                <li className="list-inline-item social-github">
                  <a href="https://github.com/leagueofcake" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faGithub} />
                  </a>
                </li>
                <li className="list-inline-item social-linkedin">
                  <a href="https://linkedin.com/in/dominic-fung" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </li>
              </ul>
              <br />
            </div>
          </div>
        {/* <div onClick={() => scrollTo('#me')}><ScrollIndicator onClick={() => scrollTo('#me')}/></div> */}
        </div>
      </div>
    </header>
  );
};

export default Masthead;